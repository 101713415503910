/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "../../../services/api";
import ReactLoading from "react-loading";
import BookIcon from "@mui/icons-material/Book";
import { reactLocalStorage } from "reactjs-localstorage";
import "./index.css";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { JobStatusEnum } from "helpers/common-helper";
import { calculateAge } from "helpers/common-helper";

const Tables = (props) => {
  const size = useWindowSize();
  const [searchText, setSearchText] = useState("");
  const [userId, setUserId] = useState(props.match.params.id);
  const [jobTermination, setJobTermination] = useState([]);
  const [jobDeletion, setJobDeletion] = useState(props.match.params.id);
  const [jobId, setJobId] = useState(props.match.params.id);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [allJobs, setAllJobs] = useState([]);
  const [loggedUser, setLoggedUser] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openModelTermination, setOpenModelTermination] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});

  useEffect(async () => {
    const user = await reactLocalStorage.getObject("userData");
    setLoggedUser(user);
    setIsLoading(true);
    await getAllJobs();
    await getRequestForJobTermination();
    setIsLoading(false);
  }, [reload]);
  async function getRequestForJobTermination() {
    try {
      const jobTermination = await api.getAllJobs("");
      if (!jobTermination.length) {
        await setJobTermination(jobTermination.data);
      }
    } catch (e) {}
  }
  async function getAllJobs() {
    try {
      setIsLoading(true);
      const allJobs = await api.getAllJobs(``);
      if (!allJobs.length) {
        await setAllJobs(allJobs.data);
      }
      setIsLoading(false);
    } catch (e) {}
  }

  function createdAtMonthCal(createdAt) {
    let getDate1 = new Date();
    let getDate2 = new Date(createdAt);

    let month1 = getDate1.getMonth();
    let month2 = getDate2.getMonth();

    let day1 = getDate1.getDate();
    let day2 = getDate2.getDate();

    let year1 = getDate1.getFullYear();
    let year2 = getDate2.getFullYear();

    let dayDiff = day1 - day2;
    let monthDiff = month1 - month2;
    let yearDiff = year1 - year2;
    if (yearDiff >= 0) {
      if (yearDiff > 0 && monthDiff > 0 && dayDiff > 0) {
        return yearDiff + " Years ago";
      } else if (monthDiff > 0) {
        return monthDiff + " Month ago";
      } else {
        return dayDiff + " Days ago";
      }
    } else {
      return "Error";
    }
  }

  function convertDate(date) {
    var today = new Date(date);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      today.getDate() +
      " " +
      monthNames[today.getMonth()] +
      ", " +
      today.getFullYear()
    );
  }
  function getCurrentDateTime() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    // Zero-padding the hours and minutes if needed
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    const formattedDateTime = `${formattedHours}:${formattedMinutes} - ${day} ${month}, ${year}`;
    return formattedDateTime;
  }

  async function viewJob(job) {
    const data = {
      adminId: loggedUser._id,
      jobId: job._id,
      activityType: "View Tuition",
      dateTime: getCurrentDateTime(),
    };
    await api.postActivitylog(data);
  }
  async function viewProfile(user, type) {
    const data = {
      adminId: loggedUser._id,
      userId: user._id,
      activityType: "View Profile",
      message: type,
      dateTime: getCurrentDateTime(),
    };
    await api.postActivitylog(data);
  }
  return (
    <>
      <ToastContainer />
      <Header />
      <hr
        style={{
          // height: "1px",
          borderWidth: 0,
          color: "rgba(255, 255, 255, 0.2)",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          width: "90%",
        }}
      />

      {/* Page content */}
      <Container
        className={window.innerWidth <= 1366 ? "mt--8" : "mt--9"}
        fluid
        style={{ height: 686 }}
      >
        {/* Table */}
        <Row style={{ height: "100%" }}>
          <div className="col" style={{ height: "100%" }}>
            <Card
              className="shadow"
              style={{ minHeight: "600px", height: "100%" }}
            >
              <CardHeader
                className="border-0"
                style={
                  size.width < 850
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        padding: "10x",
                        alignItems: "center",
                        minHeight: "60px",
                        width: "97%",
                      }
                    : {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px 30px 10px 30px",
                        minHeight: "60px",
                        width: "100%",
                      }
                }
              >
                <h3 className="mb-0">Job Posts</h3>

                <div
                  style={{ minWidth: "280px" }}
                  className={size.width > 840 ? "jobPosting-search" : "main2"}
                >
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <SearchIcon />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="search"
                      type="search"
                      label="Search"
                      style={{
                        backgroundColor: "white",
                        color: "Black",
                        height: "47px",
                        marginRight: "12px",
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Search by course or tutee"
                    ></Input>
                  </InputGroup>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead
                  className="thead-light sticky-top"
                  style={{ top: "-3px" }}
                >
                  <tr>
                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        width: "170px",
                      }}
                    >
                      TUITION
                    </th>
                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        width: "200px",
                      }}
                    >
                      POSTED BY
                    </th>
                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        width: "200px",
                      }}
                    >
                      Child
                    </th>
                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                      }}
                    >
                      POSTED
                    </th>
                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                      }}
                    >
                      START DATE
                    </th>
                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                      }}
                    >
                      DURATION
                    </th>
                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                      }}
                    >
                      RATE
                    </th>
                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                      }}
                    >
                      TUTOR REQUESTS
                    </th>
                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                      }}
                    >
                      Job Status
                    </th>
                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                      }}
                    ></th>
                    {/* <th scope="col" style={{ fontSize: '60%', fontWeight: 'bold' }}></th> */}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr
                      style={{
                        width: "100%",
                        height: "100%",
                        marginLeft: "10rem",
                      }}
                    >
                      <td colSpan={9}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <ReactLoading
                            type={"spin"}
                            color={"#ff6700"}
                            height={30}
                            width={30}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    allJobs
                      .filter((val) => {
                        if (searchText === "") {
                          return val;
                        } else if (
                          val.course[0]?.courseName
                            .toLocaleLowerCase()
                            .includes(searchText?.toLocaleLowerCase())
                        ) {
                          return val;
                        } else if (
                          val?.courseLevel
                            .toLocaleLowerCase()
                            .includes(searchText?.toLocaleLowerCase())
                        ) {
                          return val;
                        } else if (
                          val.tutee[0]?.name
                            .toLocaleLowerCase()
                            .includes(searchText?.toLocaleLowerCase())
                        ) {
                          return val;
                        } else if (
                          val?.tutee[0]?.memberId
                            .toLocaleLowerCase()
                            .includes(searchText?.toLocaleLowerCase())
                        ) {
                          return val;
                        }
                      })
                      .map((user, key) => (
                        <tr key={key}>
                          <td scope="row">
                            <Media className="align-items-center">
                              <BookIcon
                                sx={{
                                  color: user?.course[0]?.courseColor
                                    ? user.course[0].courseColor
                                    : " #ced4da",
                                  width: "40px",
                                  height: "40px",
                                }}
                              />

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  textAlign: "left",
                                  marginLeft: "6px",
                                }}
                              >
                                <Link
                                  className="mb-0 "
                                  style={{ fontSize: "100%", fontWeight: 600 }}
                                  to={`/admin/TutionDetails/${user?._id}`}
                                  onClick={() => viewJob(user)}
                                >
                                  {user.course[0]?.courseName ?? "N/A"}
                                </Link>
                                <span
                                  style={{
                                    color: "#9a9a9a",
                                    fontSize: "85%",
                                  }}
                                >
                                  {user?.courseLevel}{" "}
                                </span>
                              </div>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              {user.tutee[0]?.photoUrl ? (
                                <img
                                  className="rounded-circle"
                                  src={user.tutee[0]?.photoUrl}
                                  style={{
                                    width: 38,
                                    height: 38,
                                  }}
                                />
                              ) : (
                                <Avatar
                                  style={{
                                    width: 38,
                                    height: 38,
                                    backgroundColor: "#ced4da",
                                  }}
                                  alt={user.tutee[0]?.name}
                                  src="/"
                                />
                              )}

                              <Media>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    textAlign: "left",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <Link
                                    className="mb-0 "
                                    style={{
                                      fontSize: "100%",
                                      fontWeight: 600,
                                    }}
                                    to={`/admin/Tutee/${user.tutee[0]?._id}`}
                                    onClick={() =>
                                      viewProfile(user.tutee[0], "tutee")
                                    }
                                  >
                                    {user.tutee[0]?.name}
                                  </Link>
                                  <span
                                    style={{
                                      color: "#9a9a9a",
                                      fontSize: "85%",
                                    }}
                                  >
                                    {user.tutee[0]?.memberId}
                                  </span>
                                </div>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            {user.child[0]?.name ? (
                              <>
                                <Media className="align-items-center">
                                  <Avatar
                                    style={{
                                      width: 38,
                                      height: 38,
                                      backgroundColor: "#ced4da",
                                    }}
                                    alt={user.child[0]?.name}
                                    src="/"
                                  />

                                  <Media>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        textAlign: "left",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "100%",
                                          fontWeight: 600,
                                          color: "#525f7f",
                                        }}
                                      >
                                        {user.child[0]?.name ?? "--"}
                                      </span>
                                      <div className="postpaidTag">{`${calculateAge(
                                        user.child[0]?.dateOfBirth
                                      )} years old`}</div>
                                    </div>
                                  </Media>
                                </Media>
                              </>
                            ) : (
                              "--"
                            )}
                          </td>
                          <td style={{ fontSize: "90%" }}>
                            {createdAtMonthCal(user?.createdAt)}
                          </td>
                          <td style={{ fontSize: "90%" }}>
                            {convertDate(user.startDate)}
                          </td>
                          <td style={{ fontSize: "90%" }}>
                            {user.courseDuration}
                          </td>
                          <td style={{ fontSize: "90%" }}>
                            {`$${user.priceperHour} / hour`}
                          </td>
                          <td>{`${user?.requests?.length} Requests`}</td>
                          <td>{JobStatusEnum(user?.jobStatus).view}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() => {
                                    setOpenModel(true);
                                    setJobDeletion(user._id);
                                    setSelectedJob(user);
                                  }}
                                  //   onClick={() => {

                                  //     api.deleteJob(user._id);
                                  //     allJobs();
                                  // }}
                                >
                                  Delete
                                </DropdownItem>
                                {/* <DropdownItem
                                  onClick={() => {
                                    setOpenModelTermination(true);
                                    setJobDeletion(user._id);
                                  }}
                                //   onClick={() => {

                                //     api.deleteJob(user._id);
                                //     allJobs();
                                // }}
                                >
                                  Terminate Requested
                                </DropdownItem> */}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                          <Modal
                            className="modal-dialog-centered jobPost-delete-model"
                            isOpen={openModelTermination}
                            toggle={() =>
                              setOpenModelTermination(!openModelTermination)
                            }
                          >
                            <div className="DeleteModalMain">
                              <div className="ModalDeleteText">
                                Are you sure about terminate this job post?
                              </div>
                              <hr
                                style={{
                                  height: "1px",
                                  color: "rgba(255, 255, 255, 0.2)",
                                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                                  width: "90%",
                                  margin: "1rem 1rem",
                                }}
                              />
                              <div className="jobpostSureAboutDeleting">
                                Tutee has an outstanding balance of: <br />
                              </div>
                              <div className="DeleteButtons">
                                <button
                                  className="DeleteCancle"
                                  onClick={() => setOpenModelTermination(false)}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="DeleteOk"
                                  onClick={async () => {
                                    setIsLoading(true);
                                    setOpenModelTermination(false);
                                    // await api.deleteJob(jobDeletion);
                                    setReload(true);
                                    setReload(false);
                                    setIsLoading(false);
                                  }}
                                >
                                  {isLoading ? (
                                    <ReactLoading
                                      type={"spin"}
                                      color={"#ffffff"}
                                      height={30}
                                      width={30}
                                    />
                                  ) : (
                                    "Yes"
                                  )}
                                </button>
                              </div>
                            </div>
                          </Modal>
                          <Modal
                            className="modal-dialog-centered jobPost-delete-model"
                            isOpen={openModel}
                            toggle={() => setOpenModel(!openModel)}
                          >
                            <div className="DeleteModalMain">
                              <div className="ModalDeleteText">
                                Are you sure about deleting this job post?
                              </div>
                              <hr
                                style={{
                                  height: "1px",
                                  color: "rgba(255, 255, 255, 0.2)",
                                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                                  width: "90%",
                                  margin: "1rem 1rem",
                                }}
                              />
                              <div className="jobpostSureAboutDeleting">
                                This will delete all associated entities like:{" "}
                                <br />
                                <span className="jobpostDeletData">
                                  Tutuor Job Requests, <br />
                                  Job Schedule, <br />
                                  Job Billing, <br />
                                  Class Reschedule Requests, <br />
                                  Bank Transfer Requests. <br />
                                </span>
                              </div>
                              <div className="DeleteButtons">
                                <button
                                  className="DeleteCancle"
                                  onClick={() => setOpenModel(false)}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="DeleteOk"
                                  onClick={async () => {
                                    setIsLoading(true);
                                    setOpenModel(false);
                                    if (selectedJob.jobStatus === 1) {
                                      await api.deleteJob(jobDeletion);
                                    } else {
                                      toast.error(
                                        "Only posted jobs can be deleted!"
                                      );
                                    }
                                    setReload(true);
                                    setReload(false);
                                    setIsLoading(false);
                                  }}
                                >
                                  {isLoading ? (
                                    <ReactLoading
                                      type={"spin"}
                                      color={"#ffffff"}
                                      height={30}
                                      width={30}
                                    />
                                  ) : (
                                    "Yes"
                                  )}
                                </button>
                              </div>
                            </div>
                          </Modal>
                        </tr>
                      ))
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
export default Tables;
