/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  // InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import api from "../../services/api";
import { reactLocalStorage } from "reactjs-localstorage";
import dashboardWhite from "../../assets/img/icons-white/Dashboard.png";
import dashboardBlack from "../../assets/img/icons-black/Dashboard.png";
import leadsWhite from "../../assets/img/icons-white/Leads.png";
import leadsBlack from "../../assets/img/icons-black/Leads.png";
import usersWhite from "../../assets/img/icons-white/Users.png";
import usersBlack from "../../assets/img/icons-black/Users.png";
import jobPostsWhite from "../../assets/img/icons-white/Job posts.png";
import joPostsBlack from "../../assets/img/icons-black/Job posts.png";
import coursesWhite from "../../assets/img/icons-white/Courses.png";
import coursesBlack from "../../assets/img/icons-black/Courses.png";
import billingWhite from "../../assets/img/icons-white/Billing.png";
import billingBlack from "../../assets/img/icons-black/Billing.png";
import pushWhite from "../../assets/img/icons-white/Push.png";
import pushBlack from "../../assets/img/icons-black/Push.png";
import activityWhite from "../../assets/img/icons-white/Activity log.png";
import activityBlack from "../../assets/img/icons-black/Activity log.png";
// import settingWhite from '../../assets/img/icons-white/Setting.png'
// import settingBlack from '../../assets/img/icons-black/Setting.png'
import logoutWhite from "../../assets/img/icons-white/Logout.png";
import logoutBlack from "../../assets/img/icons-black/Logout.png";
import memberWhite from "../../assets/img/icons-white/Membership.png";
import memberBlack from "../../assets/img/icons-black/Membership.png";
import voucherWhite from "../../assets/img/icons-white/Vouchers.png";
import voucherBlack from "../../assets/img/icons-black/Vouchers.png";
import { useEffect } from "react";
import "./index.css";
import { useHistory } from "react-router-dom";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const [requestCount, setRequestCount] = useState(0);
  const [index, setIndex] = useState(0);
  const [sidebarIndex, setSidebarIndex] = useState(0);
  const [loggedUser, setUser] = useState(false);
  const history = useHistory();

  useEffect(async () => {
    const user = await reactLocalStorage.getObject("userData");
    const sidebarIndexObj = await reactLocalStorage.getObject("sidebar-index");
    setSidebarIndex(sidebarIndexObj.index);
    setUser(user);
  }, []);
  useEffect(async () => {
    const adminRequests = await api.getAdminRequestCount().then((res) => {
      const qualificationRequests = res?.data?.qualificationRequests;
      const terminationRequests = res?.data?.terminationRequests;
      setRequestCount(qualificationRequests + terminationRequests);
    });
  }, [sidebarIndex]);

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = (key) => {
    setIndex(key);
    setCollapseOpen(false);
  };

  const logout = async () => {
    await reactLocalStorage.remove("userData");
    await reactLocalStorage.remove("token");
    await reactLocalStorage.setObject("sidebar-index", { index: 0 });

    history.push("/auth/login");
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    const notUseRoutes = ["Tutee", "Tutor", "Tution Details", "Login"];
    return routes.map((prop, key) => {
      const isActive = sidebarIndex === key;
      return !notUseRoutes.includes(prop.name) ? (
        <NavItem key={key}>
          <NavLink
            className="sideBar-navLink"
            to={
              prop.name === "Logout" ? "/auth/login" : prop.layout + prop.path
            }
            tag={NavLinkRRD}
            onClick={() => {
              closeCollapse(key);
              reactLocalStorage.setObject("sidebar-index", { index: key });
              setSidebarIndex(key);
              prop.name === "Logout" ? logout() : null;
            }}
            activeClassName="active"
            style={
              sidebarIndex === key
                ? {
                    padding: "5px",
                    background: "#FF6700",
                    color: "#fff",
                    borderRadius: "4px",
                    marginLeft: "4.8%",
                    marginRight: "4.8%",
                  }
                : {
                    padding: "5px",
                    marginLeft: "4.8%",
                    marginRight: "4.8%",
                    color: "#333333",
                  }
            }
          >
            {prop.name === "Dashboard" ? (
              sidebarIndex === key ? (
                <img src={dashboardWhite} />
              ) : (
                <img src={dashboardBlack} />
              )
            ) : null}
            {prop.name === "Leads" ? (
              sidebarIndex === key ? (
                <img src={leadsWhite} />
              ) : (
                <img src={leadsBlack} />
              )
            ) : null}
            {prop.name === "Users" ? (
              sidebarIndex === key ? (
                <img src={usersWhite} />
              ) : (
                <img src={usersBlack} />
              )
            ) : null}
            {prop.name === "Job Posts" ? (
              sidebarIndex === key ? (
                <img src={jobPostsWhite} />
              ) : (
                <img src={joPostsBlack} />
              )
            ) : null}
            {prop.name === "Courses" ? (
              sidebarIndex === key ? (
                <img src={coursesWhite} />
              ) : (
                <img src={coursesBlack} />
              )
            ) : null}
            {prop.name === "Billing" ? (
              sidebarIndex === key ? (
                <img src={billingWhite} />
              ) : (
                <img src={billingBlack} />
              )
            ) : null}
            {prop.name === "Push Notification" ? (
              sidebarIndex === key ? (
                <img src={pushWhite} />
              ) : (
                <img src={pushBlack} />
              )
            ) : null}
            {prop.name === "Activity Log" ? (
              sidebarIndex === key ? (
                <img src={activityWhite} />
              ) : (
                <img src={activityBlack} />
              )
            ) : null}
            {prop.name === "Logout" ? (
              sidebarIndex === key ? (
                <img src={logoutWhite} />
              ) : (
                <img src={logoutBlack} />
              )
            ) : null}
            {prop.name === "Vouchers" ? (
              sidebarIndex === key ? (
                <img src={voucherWhite} />
              ) : (
                <img src={voucherBlack} />
              )
            ) : null}
            {prop.name === "Memberships" ? (
              sidebarIndex === key ? (
                <img src={memberWhite} />
              ) : (
                <img src={memberBlack} />
              )
            ) : null}
            {prop.name === "Leads" ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginLeft: "10px",
                    fontSize: "0.9vw",
                    fontWeight: "500",
                  }}
                >
                  {prop.name}
                </span>
                <div
                  style={{
                    alignContent: "center",
                    // marginLeft: "auto",
                    backgroundColor: "rgb(218 218 218)",
                    color: "#191919",
                    borderRadius: "20px",
                    textAlign: "center",
                    padding: "3px 8px 3px 8px",
                    width: "auto",
                    marginRight: "6px",
                    fontWeight: "400",
                    lineHeight: "1",
                    fontSize: "0.7vw",
                  }}
                >
                  <span>{requestCount} new</span>
                </div>
              </div>
            ) : (
              <>
                <span
                  style={{
                    marginLeft: "10px",
                    fontSize: "0.9vw",
                    fontWeight: "500",
                  }}
                >
                  {prop.name}
                </span>
              </>
            )}
          </NavLink>
        </NavItem>
      ) : null;
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white sideBar-cont"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" />
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/theme/team-1-800x800.jpg")
                        .default
                    }
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-calendar-grid-58" />
                <span>Activity</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-support-16" />
                <span>Support</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={(e) => alert("asd")}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              {/* <InputGroupAddon addonType="prepend"> */}
              <InputGroupText>
                <span className="fa fa-search" />
              </InputGroupText>
              {/* </InputGroupAddon> */}
            </InputGroup>
          </Form>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
          {/* Divider */}
          <hr className="my-3" />
          {/* Heading */}
          {/* <h6 className="navbar-heading text-muted">Documentation</h6> */}
          {/* Navigation */}
          {/* <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-admin-sidebar">
                <i className="ni ni-spaceship" />
                Getting started
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/colors?ref=adr-admin-sidebar">
                <i className="ni ni-palette" />
                Foundation
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/alerts?ref=adr-admin-sidebar">
                <i className="ni ni-ui-04" />
                Components
              </NavLink>
            </NavItem>
          </Nav> */}
          {/* <Nav className="mb-md-3" navbar>
            <NavItem className="active-pro active">
              <NavLink href="https://www.creative-tim.com/product/argon-dashboard-pro-react?ref=adr-admin-sidebar">
                <i className="ni ni-spaceship" />
                Upgrade to PRO
              </NavLink>
            </NavItem>
          </Nav> */}
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
