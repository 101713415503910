import "./index.css";

const JobStatusView = ({ jobStatus = 1, title = "Posted" }) => {
  return (
    <>
      <div
        className="jobStatus"
        style={{
          color: jobStatus === 1 ? "#000" : "#fff",
          backgroundColor:
            jobStatus === 1
              ? "#e9ecef"
              : jobStatus === 2
              ? "#12bf0f"
              : jobStatus === 3
              ? "rgb(18, 191, 15)"
              : jobStatus === 4
              ? "#ff6700"
              : jobStatus === 5
              ? "#ff2020"
              : "#ff2020",
        }}
      >
        <div>{title}</div>
      </div>
    </>
  );
};

export const JobStatusEnum = (jobStatus) => {
  let dataForStatus = {};
  switch (jobStatus) {
    case 1:
      dataForStatus = {
        backgroundColor: "#e9ecef",
        textColor: "rgb(0, 0, 0)",
        title: "Posted",
        view: <JobStatusView jobStatus={jobStatus} title="Posted" />,
      };
      return dataForStatus;
    case 2:
      dataForStatus = {
        backgroundColor: "#12bf0f",
        textColor: "rgb(0, 0, 0)",
        title: "Ongoing",
        view: <JobStatusView jobStatus={jobStatus} title="Ongoing" />,
      };
      return dataForStatus;
    case 3:
      dataForStatus = {
        backgroundColor: "rgb(18, 191, 15)",
        textColor: "rgb(0, 0, 0)",
        title: "Completed",
        view: <JobStatusView jobStatus={jobStatus} title="Completed" />,
      };
      return dataForStatus;
    case 4:
      dataForStatus = {
        backgroundColor: "#ff6700",
        textColor: "rgb(0, 0, 0)",
        title: "Terminate Request",
        view: <JobStatusView jobStatus={jobStatus} title="In Dispute" />,
      };
      return dataForStatus;
    case 5:
      dataForStatus = {
        backgroundColor: "#ff2020",
        textColor: "#fff",
        title: "Terminated",
        view: <JobStatusView jobStatus={jobStatus} title="Terminated" />,
      };
      return dataForStatus;

    default:
      return <div>Pending</div>;
  }
};

export const getCurrentUserInfo = () => {
  return JSON.parse(localStorage.getItem("userData"));
};

export function calculateAge(birthDate) {
  // Create a new Date object from the provided date
  const birth = new Date(birthDate);

  // Get the current date
  const today = new Date();

  // Calculate the difference in years
  let age = today.getFullYear() - birth.getFullYear();

  // Adjust the age if the birth date hasn't occurred yet this year
  const monthDiff = today.getMonth() - birth.getMonth();
  const dayDiff = today.getDate() - birth.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
}

export function calculateDaysAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();

  // Calculate the difference in time (milliseconds)
  const differenceInTime = now - date;

  // Convert the difference to days
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  if (differenceInDays === 0) {
    return "Today";
  } else if (differenceInDays === 1) {
    return "1 day ago";
  } else {
    return `${differenceInDays} days ago`;
  }
}

export function isDatePassed(dateString) {
  // Parse the input date
  const inputDate = new Date(dateString);

  // Get today's date without the time (to compare only the date part)
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to 00:00:00 to ignore the time component

  // Compare the input date with today
  return inputDate < today;
}

export function removeEmptyValues(obj) {
  // Create a new object to hold the filtered properties
  const newObj = {};

  // Iterate over each key in the original object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // Only add properties to the new object if they are not null, undefined, or an empty string
      if (value !== null && value !== undefined && value !== "") {
        newObj[key] = value;
      }
    }
  }

  return newObj;
}
