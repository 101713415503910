import "./index.css";

import { TabsUnstyled } from "@mui/base";
import Header from "components/Headers/Header";
import StatusTag from "components/StatusTag";
import Tabs from "components/Tabs";
import { calculateDaysAgo } from "helpers/common-helper";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import api from "services/api";

import CreateVoucherModal from "./CreateVoucherModal";
import styles from "./styles.module.scss";
import { isDatePassed } from "helpers/common-helper";

const VOUCHER_TYPES = ["Promotional", "Special", "Referral"];
const VOUCHER_SELECTION = [
  { value: "ALL_TUTEE", label: "All Tutee" },
  { value: "CUSTOM_TUTEE", label: "Custom Tutee" },
  {
    value: "ALL_TUTEE_MEMBER",
    label: "Tutee with Membership (Member)",
  },
  {
    value: "ALL_TUTEE_SILVER",
    label: "Tutee with Membership (Silver)",
  },
  {
    value: "ALL_TUTEE_GOLD",
    label: "Tutee with Membership (Gold)",
  },
  {
    value: "ALL_TUTEE_DIAMOND",
    label: "Tutee with Membership (Diamond)",
  },
];
const NewVoucher = () => {
  const [state, setState] = useState({
    tab: 0,
    isLoading: false,
    isEditMode: false,
    voucherStatus: "Active",
  });
  const [isOpen, setOpen] = useState(false);
  const [isVoucherActive, setIsVoucherActive] = useState("Active");
  const [voucherName, setVoucherName] = useState("");
  const [price, setPrice] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [minSpend, setMinSpend] = useState("");
  const [date, setDate] = useState("");
  const [dropdown, setuser] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allTutees, setAllTutees] = useState([]);
  const [allPendingTutees, setAllPendingTutees] = useState([]);
  const [publishTo, setPublishTo] = useState("");

  const [publishToList, setPublishToList] = useState([]);
  const [err, setErr] = useState("");
  const [vouchers, setVouchers] = useState([]);
  const [voucherMembers, setVoucherMembers] = useState([]);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [selectedVoucherIndex, setSelectedVoucherIndex] = useState(0);
  const [newMemberList, setNewMemberList] = useState([]);
  const [selectedNewMemberList, setSelectedNewMemberList] = useState([]);
  const [splitVoucherModel, setSplitVoucherModel] = useState(0);
  const [splitVoucherValueOne, setSplitVoucherValueOne] = useState({});
  const [splitVoucherValueTwo, setSplitVoucherValueTwo] = useState({});
  const [splitVoucherButtonDisabled, setSplitVoucherButtonDisabled] =
    useState(true);
  const [selectedTutee, setSelectedTutee] = useState("");
  const [editModal, setEditModal] = React.useState(false);
  const [isAutoAdd, setIsAutoAdd] = React.useState(false);
  const handleOpen = () => setEditModal(true);

  const handleVoucherStatus = (status) => {
    setIsVoucherActive(status);
    setErr("");
    setState((prev) => ({
      ...prev,
      voucherStatus: status,
    }));
    setSelectedVoucher({ ...selectedVoucher, promStatus: status });
  };

  const handleLoader = (loading) => {
    setState((prev) => ({
      ...prev,
      isLoading: loading ?? !prev.isLoading,
    }));
  };

  const handleTab = (tabId) => {
    setState((prev) => ({
      ...prev,
      tab: tabId,
    }));
  };

  const handleSelectChange = (e) => {
    setPublishTo(e.target.value);
    setErr("");
  };

  const handleTuteeChange = (e) => {
    setSelectedTutee(e.target.value);
    setErr("");
  };

  async function getAllTutee() {
    try {
      const allUsers = await api.getAllUsers(`?userType=Tutee`);
      // if (!allusers.length)
      const filteredData = allUsers.data.map((user, id) => {
        let temp = user;

        temp = { id: user._id, name: `${user.name} (${user.email})` };
        return temp;
      });
      setAllTutees(filteredData);
      setAllPendingTutees(filteredData);
      // }
    } catch (e) {
      console.log(e?.response?.data?.message);
    }
  }

  async function getVouchers(isEdit = false) {
    handleLoader(true);
    const queryParams = `?voucherType=${VOUCHER_TYPES[state.tab]}`;
    try {
      const vouchers = await api.getVouchers(queryParams);
      if (
        vouchers &&
        vouchers.data &&
        vouchers.data.length &&
        selectedVoucher &&
        isEdit
      ) {
        const temp = vouchers.data.find(
          (item) => item.id === selectedVoucher.id
        );
        setVoucherMembers(temp?.usersList);
        setSelectedVoucher(temp);
        setIsVoucherActive(temp.promStatus);
        setPublishTo(temp?.recievers);
        let pendingTuteeList = [];
        const selectedUsersList =
          temp.usersList && temp.usersList.length
            ? temp?.usersList
                .map((s) => {
                  if (s?.user && s.user._id && s?.redeemStatus === "Pending") {
                    return {
                      id: s.user._id,
                      redeemStatus: s?.redeemStatus,
                      name: `${s.user.name} (${s.user.email})`,
                    };
                  } else if (
                    s?.user &&
                    s.user._id &&
                    s?.redeemStatus === "Redeemed"
                  ) {
                    pendingTuteeList.push({
                      id: s.user._id,
                      redeemStatus: s?.redeemStatus,
                      name: `${s.user.name} (${s.user.email})`,
                    });
                  } else {
                    return {
                      id: s.user._id,
                      redeemStatus: s?.redeemStatus,
                      name: `${s.user.name} (${s.user.email})`,
                    };
                  }
                })
                .filter(Boolean)
            : [];
        handlePublishToListSelect(selectedUsersList, pendingTuteeList);
      }
      setVouchers(vouchers.data?.reverse());
      handleLoader(false);
    } catch (e) {
      handleLoader(false);
    }
  }

  const postVoucher = async () => {
    if (voucherName || state.isEditMode) {
      if (shortDescription || state.isEditMode) {
        if (price || state.isEditMode) {
          if (minSpend || state.isEditMode) {
            if (publishTo) {
              if (
                (publishTo === "CUSTOM_TUTEE" && publishToList.length > 0) ||
                publishTo !== "CUSTOM_TUTEE"
              ) {
                if (date || state.isEditMode) {
                  setErr("");
                  handleLoader(true);

                  const userIds =
                    publishToList && publishToList.length
                      ? publishToList.map((user) => user?.id)
                      : [];
                  const userIsCSV = userIds;
                  const data = {
                    title: voucherName,
                    expiryDate: date,
                    price,
                    minSpend,
                    shortDescription,
                    redeemStatus: "N/A",
                    voucherType: "Promotional",
                    // promStatus: "Active",
                    promStatus: state.voucherStatus,
                    recievers: publishTo ? publishTo : "",
                    userIds: userIsCSV,
                    autoAddNewUser: isAutoAdd,
                  };

                  const updatePayload = {
                    title: !isEmpty(voucherName)
                      ? voucherName
                      : selectedVoucher["title"],
                    shortDescription: !isEmpty(shortDescription)
                      ? shortDescription
                      : selectedVoucher["shortDescription"],
                    expiryDate: !isEmpty(date)
                      ? date
                      : selectedVoucher["expiryDate"],
                    price: !isEmpty(price) ? price : selectedVoucher["price"],
                    minSpend: !isEmpty(minSpend)
                      ? minSpend
                      : selectedVoucher["minSpend"],
                    voucherType: "Promotional",
                    promStatus: state.voucherStatus,
                    recievers: publishTo ? publishTo : "",
                    userIds: userIsCSV,
                    autoAddNewUser: isAutoAdd,
                    // usersId: selectedTutee ? selectedTutee : "",
                    // promStatus: state.voucherStatus,
                  };
                  const apiToCall = state.isEditMode
                    ? api.updateVoucher
                    : api.postVoucher;

                  await apiToCall(
                    state.isEditMode ? updatePayload : data,
                    selectedVoucher?._id
                  );
                  toast.success(
                    `Voucher ${
                      state.isEditMode ? "updated" : "created"
                    } successfully!`
                  );
                  //   setPublishTo([]);
                  getVouchers(state?.isEditMode ? true : false);
                  setOpen(false);
                  setState((prev) => ({
                    ...prev,
                    isEditMode: false,
                    isLoading: false,
                    voucherStatus: "Active",
                  }));
                } else {
                  setErr("Please select expiry date");
                }
              } else {
                setErr("Please add tutee");
              }
            } else {
              setErr("Please select tutee type");
            }
          } else {
            setErr("Please enter min spend amount");
          }
        } else {
          setErr("Please enter price");
        }
      } else {
        setErr("Please enter short description");
      }
    } else {
      setErr("Please enter voucher title");
    }
  };

  const makeNewmemberList = () => {
    const newMemberList = [];
    allTutees.forEach((tutee) => {
      let flag = false;
      voucherMembers.forEach((element) => {
        if (element.user._id === tutee._id) {
          flag = true;
        }
      });
      if (!flag) {
        newMemberList.push(tutee);
      }
    });
    setNewMemberList(newMemberList);
  };

  const handleSplitVoucherButtonDisabled = () => {
    const totalAmount = Number(
      Number(splitVoucherValueOne) + Number(splitVoucherValueTwo)
    );
    if (totalAmount === Number(selectedVoucher?.price)) {
      setSplitVoucherButtonDisabled(false);
    } else if (totalAmount !== Number(selectedVoucher?.price)) {
      setSplitVoucherButtonDisabled(true);
    }
  };

  const handleDeleteVoucher = (selectedVoucher) => {
    api
      .deleteVoucher(selectedVoucher._id)
      .then((resp) => {
        toast.success("Voucher Deleted Successfully");
        getVouchers();
        setVoucherMembers([]);
        setSelectedVoucher(null);
        setIsVoucherActive(false);
        setPublishTo("");
      })
      .catch((error) => {
        console.log({ error });
        toast.error("Unable to delete voucher.");
      });
  };

  useEffect(() => {
    handleSplitVoucherButtonDisabled();
  }, [splitVoucherValueOne, splitVoucherValueTwo]);

  useEffect(async () => {
    getAllTutee();
    getVouchers();
    setErr("");
    setVoucherMembers([]);
    setSelectedVoucher(null);
    setPublishTo("");
    setIsVoucherActive("Active");
    setPublishToList([]);
    setState((prev) => ({
      ...prev,
      isEditMode: false,
    }));
  }, [state.tab]);

  function onSelect(selectedList, selectedItem) {
    setPublishToList(selectedList);
    setErr("");
  }

  function onSelect1(selectedList, selectedItem) {
    let list = selectedList.map((s) => {
      const temp = {
        user: s,
        isRedeemed: false,
        joinDate: new Date(),
      };
      return temp;
    });
    setSelectedNewMemberList(list);
  }
  async function handleMarkAsRefunded(refundData) {
    const { _id, redeemStatus } = refundData;
    const apiToCall = api.updateVoucher;
    await apiToCall({ redeemStatus }, _id).then((res) => {
      getVouchers();
      setOpen(false);
      setState((prev) => ({
        ...prev,
        isEditMode: false,
        isLoading: false,
        voucherStatus: "Active",
      }));
    });
  }
  const handlePublishToListSelect = (all, pendingTutees) => {
    setPublishToList(all);
    const tuteeList = allTutees.filter(
      (tutee) =>
        !pendingTutees.some((pendingTutee) => pendingTutee.name === tutee.name)
    );
    setAllPendingTutees(tuteeList);
  };

  return (
    <div>
      <Header />

      <Container
        fluid
        style={{ paddingBottom: "20px" }}
        className={window.innerWidth <= 1366 ? "mt--7" : "mt--9"}
      >
        <Row
          style={{ display: "flex", justifyContent: "space-between" }}
          className={styles.voucherTabRow}
        >
          <Col
            xs={12}
            md={12}
            lg={12}
            xl={12}
            className={styles.topVoucherRowCol}
          >
            <div className={styles.voucherTitle}>Vouchers</div>

            <TabsRow handleTab={handleTab} />
          </Col>
        </Row>

        {state.tab === 0 && (
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
              margin: "0px 2px",
            }}
          >
            <Col
              xs={12}
              md={12}
              lg={5}
              xl={5}
              style={{
                minWidth: "49%",
                padding: 0,
                boxShadow: "0 0 2rem 0 rgba(136, 152, 170, 0.15)",
              }}
            >
              <Card style={{ minHeight: "75vh" }} className="activtyLogShadow">
                <CardHeader className="border-0 voucher-card-head">
                  <h3 style={{ color: "#454545" }} className="mb-0">
                    Promotional Vouchers
                  </h3>
                  <Button
                    className="voucher-create-btn"
                    type="button"
                    onClick={() => {
                      setOpen(true);
                      setErr("");
                      setVoucherMembers([]);
                      setSelectedVoucher(null);
                      setPublishTo("");
                      setIsVoucherActive("Active");
                      setPublishToList([]);
                      setState((prev) => ({
                        ...prev,
                        isEditMode: false,
                      }));
                      setIsAutoAdd(false);
                    }}
                  >
                    Create New
                  </Button>
                </CardHeader>

                <hr style={{ margin: "4px 0px" }} />

                <div style={{ maxHeight: "65vh", overflowY: "scroll" }}>
                  {state.isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ReactLoading
                        type={"spin"}
                        color={"#ff6700"}
                        height={30}
                        width={30}
                      />
                    </div>
                  ) : (
                    vouchers?.map((voucher, index) => {
                      const isVoucherExpired = isDatePassed(voucher.expiryDate);
                      return (
                        <div
                          key={index}
                          className="voucher-data"
                          style={{
                            border: `1px solid 
                            ${
                              selectedVoucher &&
                              selectedVoucher._id === voucher._id
                                ? "#FF8B3C"
                                : "#D3E0ED"
                            }`,
                            backgroundColor:
                              selectedVoucher &&
                              selectedVoucher._id === voucher._id
                                ? "#FFEDE1"
                                : "#F6F9FC",
                          }}
                        >
                          <div style={{ padding: "15px 18px" }}>
                            <div className={styles.titleContainer}>
                              <div
                                style={{
                                  margin: "5px 0",
                                  color: "#9A9A9A",
                                }}
                              >
                                <div className={styles.voucherName}>
                                  <img
                                    src="/voucher-icon.svg"
                                    alt="voucher-logo"
                                  />
                                  <h2 className={styles.voucherNameHeading}>
                                    <div
                                      title={voucher?.title}
                                      className={styles.heading}
                                    >
                                      {voucher?.title ?? "N/A"}
                                    </div>
                                    <div>
                                      <StatusTag
                                        type={
                                          isVoucherExpired
                                            ? "expired"
                                            : voucher.promStatus.toLowerCase()
                                        }
                                        // isVoucher={true}
                                        title={
                                          isVoucherExpired
                                            ? "Expired"
                                            : voucher.promStatus
                                        }
                                      />
                                    </div>
                                  </h2>
                                </div>

                                <h5
                                  style={{
                                    color: "#9A9A9A",
                                    fontSize: "0.8vw",
                                    paddingTop: "10px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      marginRight: "5px",
                                    }}
                                  >
                                    {
                                      VOUCHER_SELECTION.find(
                                        (item) =>
                                          item.value === voucher?.recievers
                                      )?.label
                                    }
                                  </span>
                                    {voucher?.autoAddNewUser
                                      ? " | Auto Assign "
                                      : ""}
                                  {`| Published ` +
                                    calculateDaysAgo(voucher.createdAt)}
                                  {/* {convertDateFormat(voucher.expiryDate)} */}
                                </h5>
                              </div>
                              <div
                                style={{
                                  cursor: "pointer",
                                  // float: "right",
                                  color: "#FF6700",
                                  marginLeft: "5px",
                                }}
                                onClick={() => {
                                  setVoucherMembers(voucher?.usersList);
                                  setSelectedVoucher(voucher);
                                  setIsVoucherActive(voucher.promStatus);
                                  setIsAutoAdd(
                                    voucher.autoAddNewUser
                                      ? voucher.autoAddNewUser
                                      : false
                                  );
                                  setPublishTo(voucher?.recievers);
                                  let pendingTuteeList = [];
                                  const selectedUsersList =
                                    voucher.usersList &&
                                    voucher.usersList.length
                                      ? voucher?.usersList
                                          .map((s) => {
                                            if (
                                              s?.user &&
                                              s.user._id &&
                                              s?.redeemStatus === "Pending"
                                            ) {
                                              return {
                                                id: s.user._id,
                                                redeemStatus: s?.redeemStatus,
                                                name: `${s.user.name} (${s.user.email})`,
                                              };
                                            } else if (
                                              s?.user &&
                                              s.user._id &&
                                              s?.redeemStatus === "Redeemed"
                                            ) {
                                              pendingTuteeList.push({
                                                id: s.user._id,
                                                redeemStatus: s?.redeemStatus,
                                                name: `${s.user.name} (${s.user.email})`,
                                              });
                                            } else {
                                              return {
                                                id: s.user._id,
                                                redeemStatus: s?.redeemStatus,
                                                name: `${s.user.name} (${s.user.email})`,
                                              };
                                            }
                                          })
                                          .filter(Boolean)
                                      : [];
                                  handlePublishToListSelect(
                                    selectedUsersList,
                                    pendingTuteeList
                                  );
                                }}
                              >
                                <img
                                  src="/voucher-expand-icon.svg"
                                  alt="exapnd icon"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                //   justifyContent: "space-between",
                                //   marginTop: "20px",
                                gap: "40px",
                                fontSize: "0.8vw",
                              }}
                            >
                              <div>
                                <span
                                  style={{
                                    color: "#9A9A9A",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Price:
                                </span>{" "}
                                <span
                                  style={{
                                    color: "#454545",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ${voucher.price}
                                </span>
                              </div>
                              <div>
                                <span
                                  style={{
                                    color: "#9A9A9A",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Min. Spend:{" "}
                                </span>{" "}
                                <span
                                  style={{
                                    color: "#454545",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {voucher?.minSpend}{" "}
                                </span>
                              </div>

                              <div>
                                <span
                                  style={{
                                    color: "#9A9A9A",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Redeem Count:{" "}
                                </span>{" "}
                                <span
                                  style={{
                                    color: "#454545",
                                    fontWeight: "bold",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {voucher.usersList?.length
                                    ? `${
                                        voucher.usersList.filter(
                                          (item) =>
                                            item.redeemStatus === "Redeemed"
                                        ).length
                                      } / ${voucher.usersList.length}`
                                    : "0 / 0"}
                                </span>
                              </div>

                              <div>
                                <span
                                  style={{
                                    color: "#9A9A9A",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Expiry Date:{" "}
                                </span>{" "}
                                <span
                                  style={{
                                    color: "#454545",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {moment(voucher?.expiryDate).format(
                                    "DD MMM, YYYY"
                                  )}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </Card>
            </Col>
            <Col
              xs={12}
              md={12}
              lg={5}
              xl={5}
              style={{
                minWidth: "50%",
                padding: 0,
                boxShadow: "0 0 2rem 0 rgba(136, 152, 170, 0.15)",
              }}
            >
              <Card style={{ height: "75vh" }} className="shadow">
                <CardHeader className="border-0 voucher-card-head1">
                  <div className={styles.actionDropdownBtn}>
                    <h3 className="mb-0">Redeem Details</h3>
                    {!isEmpty(selectedVoucher) && (
                      <div className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i
                              className="fas fa-ellipsis-v"
                              style={{
                                color: "#757575",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              //   href="#pablo"
                              onClick={() => {
                                setOpen(true);
                                setErr("");
                                setState((prev) => ({
                                  ...prev,
                                  isEditMode: true,
                                }));
                              }}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              // href="#pablo"
                              onClick={(e) =>
                                handleDeleteVoucher(selectedVoucher)
                              }
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    )}
                  </div>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th
                        style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        scope="col"
                      >
                        TUTEE
                      </th>
                      <th
                        style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        scope="col"
                      >
                        Redeemed Date
                      </th>
                      <th
                        style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        scope="col"
                      >
                        Invoice Id
                      </th>
                      <th
                        style={{ fontSize: "0.625vw", fontWeight: 600 }}
                        scope="col"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isEmpty(voucherMembers) ? (
                      <tr style={{ width: "100%" }}>
                        <td colSpan={3}>
                          <p style={{ fontSize: "14px", textAlign: "center" }}>
                            No Redeem Details Found
                          </p>
                        </td>
                      </tr>
                    ) : (
                      voucherMembers?.map((vm, i) => (
                        <tr key={i}>
                          <td style={{ maxWidth: "25%" }}>
                            <Media>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  textAlign: "left",
                                  // marginLeft: "10px",
                                }}
                              >
                                <Link
                                  className="mb-0 "
                                  style={{
                                    fontSize: "100%",
                                    fontWeight: 600,
                                  }}
                                  to={`/admin/Tutee/${vm.user?._id}`}
                                  // onClick={() => viewProfile(user.tutee[0], "tutee")}
                                >
                                  {vm.user?.name}
                                </Link>
                                <span
                                  style={{
                                    color: "#9a9a9a",
                                    fontSize: "85%",
                                  }}
                                >
                                  {vm.user?.memberId}
                                </span>
                              </div>
                            </Media>
                          </td>

                          <td>
                            {moment(vm?.redeemDate).format("DD MMM, YYYY")}
                          </td>
                          {/* <td>{String(vm.joinDate).substring(0, 10)}</td> */}
                          <td>
                            {vm?.invoiceId ? (
                              <div
                                //   className="tdTitle"
                                style={{ marginRight: "3px" }}
                              >
                                #{vm?.invoiceId?.substring(15)}{" "}
                              </div>
                            ) : (
                              "--"
                            )}
                          </td>
                          <td>
                            {vm?.redeemStatus ? (
                              <div
                                //   className="tdTitle"
                                style={{ marginRight: "3px" }}
                              >
                                {vm?.redeemStatus === "Pending" ? (
                                  <div
                                    className="btn-ext1"
                                    style={{
                                      backgroundColor: "rgb(233, 236, 239)",
                                      color: "rgb(51, 51, 51)",
                                    }}
                                  >
                                    Pending
                                  </div>
                                ) : // <div className="p-2 bg-green">Pending</div>
                                vm?.redeemStatus === "Redeemed" ? (
                                  <div
                                    className="btn-ext12 p-2"
                                    style={{
                                      backgroundColor: "rgb(18, 191, 15)",
                                    }}
                                  >
                                    Redeemed
                                  </div>
                                ) : (
                                  <div className="p-2 bg-green">'--'</div>
                                )}
                              </div>
                            ) : (
                              "--"
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        )}

        {state.tab === 1 && (
          <VoucherTable
            isLoading={state.isLoading}
            data={vouchers}
            handleMarkAsRefunded={handleMarkAsRefunded}
            />
          )}

        {state.tab === 2 && (
          <VoucherTable
          data={vouchers}
          isTypeReferral={true}
          isLoading={state.isLoading}
          title="Referral Vouchers"
          handleMarkAsRefunded={handleMarkAsRefunded}
          />
        )}

        <CreateVoucherModal
          err={err}
          setErr={setErr}
          state={state}
          setState={setState}
          isOpen={isOpen}
          setOpen={setOpen}
          isVoucherActive={isVoucherActive}
          setPrice={setPrice}
          setDate={setDate}
          setShortDescription={setShortDescription}
          allTutees={allTutees}
          setAllPendingTutees={setAllPendingTutees}
          allPendingTutees={allPendingTutees}
          onSelect={onSelect}
          publishTo={publishTo}
          publishToList={publishToList}
          isAutoAdd={isAutoAdd}
          setIsAutoAdd={setIsAutoAdd}
          setMinSpend={setMinSpend}
          postVoucher={postVoucher}
          selectedTutee={selectedTutee}
          setVoucherName={setVoucherName}
          handleVoucherStatus={handleVoucherStatus}
          setSelectedVoucher={setSelectedVoucher}
          handleTuteeChange={handleTuteeChange}
          handleSelectChange={handleSelectChange}
          selectedVoucher={state.isEditMode ? selectedVoucher : {}}
        />
      </Container>
    </div>
  );
};

export default NewVoucher;

const TabsRow = ({ handleTab }) => {
  return (
    <TabsUnstyled defaultValue={0} className="unstyledTab">
      <Tabs
        tabsList={[
          "Promotional Vouchers",
          "Special Vouchers",
          "Referral Vouchers",
        ]}
        handleTab={handleTab}
      />
    </TabsUnstyled>
  );
};

const VoucherTable = ({
  isLoading,
  title = "Special Vouchers",
  isTypeReferral = false,
  data = [],
  handleMarkAsRefunded = null,
}) => {
  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "20px",
      }}
    >
      <Col>
        <Card
          style={{ minHeight: "700px", maxHeight: "700px" }}
          className="shadow"
        >
          <CardHeader className="border-0 voucher-card-head1">
            <div className={styles.actionDropdownBtn}>
              <h3 className="mb-0">{title}</h3>
            </div>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  TUTEE
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  {isTypeReferral ? `Invited By` : `Tuition`}
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Title
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Price
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Created At
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Status
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Redeem Date
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600 }}
                  scope="col"
                >
                  Invoice
                </th>
                {isTypeReferral ? (
                  <th
                    style={{
                      fontSize: "0.625vw",
                      fontWeight: 600,
                    }}
                    scope="col"
                  >
                    Action
                  </th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr
                  style={{
                    width: "100%",
                    height: "100%",
                    marginLeft: "10rem",
                  }}
                >
                  <td colSpan={9}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ReactLoading
                        type={"spin"}
                        color={"#ff6700"}
                        height={30}
                        width={30}
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                data.map((voucher) => (
                  <tr key={voucher?._id}>
                    <td>
                      <Media>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "left",
                          }}
                        >
                          <Link
                            className="mb-0 "
                            style={{
                              fontSize: "100%",
                              fontWeight: 600,
                            }}
                            to={`/admin/Tutee/${voucher.user[0]?._id}`}
                            // onClick={() => viewProfile(user.tutee[0], "tutee")}
                          >
                            {voucher.user[0]?.name}
                          </Link>
                          <span
                            style={{
                              color: "#9a9a9a",
                              fontSize: "85%",
                            }}
                          >
                            {voucher.user[0]?.memberId}
                          </span>
                        </div>
                      </Media>
                    </td>
                    <td>
                      {isTypeReferral ? (
                        isEmpty(voucher.invited_by_user) ? (
                          "--"
                        ) : (
                          <Media>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                textAlign: "left",
                              }}
                            >
                              <Link
                                className="mb-0 "
                                style={{
                                  fontSize: "100%",
                                  fontWeight: 600,
                                  color: "#5e72e4",
                                }}
                                to={`/admin/Tutee/${voucher?.invited_by_user[0]?._id}`}
                                // onClick={() => viewProfile(user.tutee[0], "tutee")}
                              >
                                {voucher.invited_by_user[0]?.name}
                              </Link>
                              <span
                                style={{
                                  color: "#9a9a9a",
                                  fontSize: "85%",
                                }}
                              >
                                {voucher.invited_by_user[0]?.memberId}
                              </span>
                            </div>
                          </Media>
                        )
                      ) : isEmpty(voucher.job) ? (
                        "--"
                      ) : (
                        <Media>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              textAlign: "left",
                            }}
                          >
                            <Link
                              className="mb-0 "
                              style={{
                                fontSize: "100%",
                                fontWeight: 600,
                                color: "#5e72e4",
                              }}
                              to={`/admin/TutionDetails/${voucher.job[0]?._id}`}
                              // onClick={() => viewProfile(user.tutee[0], "tutee")}
                            >
                              {voucher.job[0]?.course[0]?.courseName}
                            </Link>
                            <span
                              style={{
                                color: "#9a9a9a",
                                fontSize: "85%",
                              }}
                            >
                              {voucher.job[0]?.courseLevel}
                            </span>
                          </div>
                        </Media>
                      )}
                    </td>
                    <td>{voucher?.title ?? "N/A"}</td>
                    <td>{voucher?.price ? `$${voucher?.price}` : "N/A"}</td>
                    <td>{moment(voucher?.createdAt).format("DD MMM, YYYY")}</td>
                    <td>
                      <StatusTag
                        title={voucher?.redeemStatus}
                        isVoucher={true}
                        type={voucher?.redeemStatus.toLowerCase()}
                      />
                    </td>
                    <td>
                      {moment(voucher?.redeemDate).format("DD MMM, YYYY")}
                    </td>

                    <td>
                      {/* {voucher?.invoiceId ?? "N/A"} */}
                      {voucher?.invoiceId ? (
                        <div style={{ marginRight: "3px" }}>
                          #{voucher?.invoiceId?.substring(15)}{" "}
                        </div>
                      ) : (
                        "--"
                      )}
                    </td>
                    {isTypeReferral ? (
                      <td className="items-center">
                        {voucher?.redeemStatus === "Pending" && voucher?.user?.[0]?.userType === 'Tutor' ? (
                          <div
                            onClick={async () => {
                              if (voucher?.redeemStatus === "Pending") {
                                handleMarkAsRefunded({
                                  _id: voucher?._id,
                                  redeemStatus: "Refunded",
                                });
                              }
                            }}
                            style={{
                              fontSize: "12px",
                              width: "110px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              items: "center",
                              // paddingBottom: "2.5px",
                            }}
                            className={
                              voucher?.redeemStatus === "Pending"
                                ? "buttonTabs1 cursor-pointer"
                                : "buttonLight11"
                            }
                          >
                            Mark Refunded
                          </div>
                        ) : null}
                      </td>
                    ) : null}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};
