/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  // ReactLoading,
  Modal,
  Table,
  Container,
  FormGroup,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  UncontrolledTooltip,
  // InputGroupAddon,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import { useState, useEffect } from "react";
import PaidIcon from "@mui/icons-material/Paid";
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
// import DatePicker from "react-datepicker";
import { reactLocalStorage } from "reactjs-localstorage";
import api from "../../../services/api";
import { CSVLink, CSVDownload } from "react-csv";
import "./index.css";

// Mui Components
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import Avatar from "@mui/material/Avatar";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TutorTable from "./tutorTable";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../Chat/firebase-config";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
// import { Input } from "reactstrap";
import { async } from "@firebase/util";
import ReactLoading from "react-loading";
import VerifyTransferModal from "./VerifyTransferModal";
import moment from "moment";
import Tabs from "components/Tabs";
import { isEmpty } from "lodash";
import Papa from "papaparse";
import ViewTutorsBillingModal from "./ViewTutorsBillingModal";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toast } from "react-toastify";
import { Path } from "services/Path";
import { BASE_URL } from "services/Path";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#FF8A3C",
      darker: "#FF8A3C",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: ${"#FF6700"};
  cursor: pointer;
  font-size: 16px;
  height: auto;
  font-weight: bold;
  background-color: transparent;
  background-color: ${"#FFF8F1"};
  width: auto;
  margin: 0.313vw 0.5vw;
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${"#FFE7CE"};
  }

  &:focus {
    color: #ffffff;
    border-radius: 6px;
    outline: 2px solid ${"#333333"};
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${"#FF6700"};
    color: ${"#FFFFFF"};
    margin: 0.313vw 0.5vw;
    height: auto;
    display: flex;
    align-items: center;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: auto;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  width: auto;
  background-color: ${"#FFF8F1"};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

class TableRow extends React.Component {
  state = {
    selectedInvoiceMonth: null,
    selectedInvoice: null,
    openModelPayments: false,
    expanded: false,
    loggedUser: {},
    openModel: false,
    openModel2: false,
    bankBillingRequest: {},
    bankBillingApproved: {},
    message: "",
    tutor: {},
    isloading: false,
  };
  async componentDidMount() {
    const user = reactLocalStorage.getObject("userData");
    this.setState({
      loggedUser: user,
      isloading: false,
      selectedInvoiceMonth: this.props.selectedMonth,
    });
    // this.getBankBilling()
  }
  toggleExpander = (e) => {
    this.setState({ expanded: !this.state.expanded });
  };
  handleOpenModel = (e) => {
    this.setState({ openModel: !this.state.openModel });
  };
  handleOpenModel2 = (e) => {
    this.setState({ openModel2: !this.state.openModel2 });
  };
  handleOpenModelPayments = (e) => {
    this.setState({ openModelPayments: !this.state.openModelPayments });
  };
  handlePayedOnDate = (payedOn) => {
    const labels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date1 = new Date(payedOn);
    const month1 = labels[date1.getMonth()];
    // const [value, setValue] = React.useState(dayjs('2022-04-07'));

    const formattedDate =
      date1.getDate() + " " + month1 + ", " + date1.getFullYear();
    return formattedDate;
  };

  getCurrentDateTime() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    // Zero-padding the hours and minutes if needed
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    const formattedDateTime = `${formattedHours}:${formattedMinutes} - ${day} ${month}, ${year}`;
    return formattedDateTime;
  }

  async viewProfile(user, type) {
    const data = {
      adminId: this.state.loggedUser._id,
      userId: user._id,
      activityType: "View Profile",
      message: type,
      dateTime: this.getCurrentDateTime(),
    };
    await api.postActivitylog(data);
  }

  async createChat(billing) {
    try {
      const { fromFunction } = this.props;
      let tuteeId = billing?.tuteeId;
      let tutorId = billing?.job[0]?.tutorId;

      const tutor = await api.getUserById(
        tutorId,
        "?userType=Tutor&reviewDetail=true&qualification=true&jobDetail=true"
      );
      const tutorData = tutor?.data?.user;
      const tuteeData = billing?.tutee[0];

      let displayName1 = tuteeData?.name;
      let displayName2 = tutorData?.name;
      let id = billing?.jobId;
      let isSupportChat = false;
      let photoUrl1 = tuteeData?.photoUrl ?? "";
      let photoUrl2 = tutorData?.photoUrl ?? "";
      let title = `${billing?.job?.[0]?.course?.[0]?.courseName} - ${billing?.job?.[0]?.courseLevel}`;
      let user1 = tuteeId;
      let user2 = tutorId;
      let userList = [tuteeId, tutorId];

      const userChatData = {
        id,
        title,
        user1,
        user2,
        userList,
        photoUrl1,
        photoUrl2,
        displayName1,
        displayName2,
        isSupportChat,
        pendingRequests: 0,
        unreadCountUser1: 0,
        unreadCountUser2: 0,
      };

      let folderName = BASE_URL?.includes("dev") || BASE_URL?.includes("localhost")
        ? "Dev-Conversations"
        : "Live-Conversations";

      await setDoc(doc(db, folderName, id), userChatData);

      this.setState({ isloading: false });
      fromFunction();
    } catch (e) {
      console.log({ e });
    }
  }

  editBankBilling = async (id, invoice, bill) => {
    try {
      const bankBillingRequest = await api
        .editBankBilling(`${id}`, { invoice: invoice })
        .then((response) => {
          if (response.status == 200) {
            this.handleOpenModel2();
            this.createChat(bill);
            this.props.getBilling();
          } else {
            throw error;
          }
        });
      this.setState({
        bankBillingApproved: bankBillingRequest.data,
        isloading: false,
      });
    } catch (error) {
      console.log({ error });
    }
  };

  editBankBillingRejected = async (id, invoice, message) => {
    try {
      const bankBillingRequest = await api.editBankBillingRejected(`${id}`, {
        invoice: invoice,
        message: message,
      });
      this.handleOpenModel2();

      this.setState(
        {
          bankBillingApproved: bankBillingRequest.data,
          isloading: false,
        },
        () => {
          this.props.getBilling();
        }
      );
    } catch (e) {}
  };

  async getBankBilling(invoice) {
    this.setState({ isloading: true });

    try {
      const bankBillingRequestApi = await api.getBankBilling(`${invoice}`);

      let lastIndex = bankBillingRequestApi?.data?.length - 1;
      const bankBillingData = bankBillingRequestApi?.data[lastIndex];
      this.setState({ bankBillingRequest: bankBillingData, isloading: false });
    } catch (e) {}
  }
  render() {
    const { bill, fromFunction } = this.props;
    const selectedMonth = this.props.selectedMonth;
    const paymentMethod = bill?.tutee[0]?.recurringPaymentMethod;

    return [
      <>
        {bill?.invoices.map(
          (invoice) =>
            invoice.month === selectedMonth &&
            this.props?.paymentStatus.includes(
              invoice.paymentStatus.toLowerCase()
            ) && (
              <tr
                style={{
                  background: bill?.job[0]?.jobStatus === 1 ? "#e1e5e8" : "",
                }}
                key={invoice.invoice}
              >
                <>
                  <td onClick={() => this.handleOpenModel()}>
                    <div
                      className="tdTitle"
                      style={{
                        color: "#5E72E4",
                        marginRight: "3px",
                        cursor: "pointer",
                      }}
                    >
                      #{invoice?.invoice.substring(15)}{" "}
                    </div>

                    {invoice?.type == "PrePaid" ? (
                      <div className="prepaidTag">Prepaid</div>
                    ) : (
                      <div className="postpaidTag">Postpaid</div>
                    )}
                  </td>

                  <td>
                    <Link
                      to={`/admin/Tutee/${bill?.tutee[0]?._id}`}
                      onClick={() => this.viewProfile(bill.tutee[0], "tutee")}
                    >
                      <div
                        className="tdTitle"
                        style={{ color: "#5E72E4", marginRight: "3px" }}
                      >
                        {bill?.tutee[0]?.name}{" "}
                      </div>
                      <div className="postpaidTag">
                        {bill?.tutee[0]?.memberId}
                      </div>

                      {paymentMethod === "Card" && (
                        <span className="postpaidTag">Card</span>
                      )}
                    </Link>
                  </td>
                  <td>
                    <Link
                      className="mb-0 "
                      style={{ fontSize: "100%", fontWeight: 600 }}
                      to={`/admin/TutionDetails/${bill?.jobId}`}
                      // onClick={() => viewJob(user)}
                    >
                      <div
                        className="tdTitle"
                        style={{ color: "#5E72E4", marginRight: "3px" }}
                      >
                        {bill?.job[0]?.course[0]?.courseName ?? "N/A"}
                      </div>
                      <div className="postpaidTag">
                        {bill?.job[0]?.courseLevel}
                      </div>
                    </Link>
                  </td>
                  {/* <td style={{ fontWeight: "bold" }}> {invoice?.month}</td> */}

                  <td>
                    {isEmpty(bill.tutor) ? (
                      "N/A"
                    ) : (
                      <Link
                        to={`/admin/Tutor/${bill?.tutor[0]?._id}`}
                        onClick={() => this.viewProfile(bill.tutor[0], "tutor")}
                      >
                        <div
                          className="tdTitle"
                          style={{ color: "#5E72E4", marginRight: "3px" }}
                        >
                          {bill?.tutor[0]?.name}{" "}
                        </div>
                        <div className="postpaidTag">
                          {bill?.tutor[0]?.memberId}
                        </div>
                      </Link>
                    )}
                  </td>

                  <td>${invoice?.salary}</td>
                  <td style={{ fontWeight: "bold" }}>${invoice?.amount}</td>
                  <td> ${invoice?.amount - invoice?.remainingAmount}</td>
                  <td>${invoice?.remainingAmount}</td>

                  {/* <td> {invoice?.dueOn}</td> */}

                  {invoice?.paymentStatus === "Review" ? (
                    <td style={{ fontSize: "12px" }}>
                      <div
                        onClick={async () => {
                          await this.getBankBilling(invoice.invoice);
                          this.setState({ selectedInvoice: invoice });
                          this.handleOpenModel2();
                        }}
                        style={{ fontSize: "12px", width: "110px" }}
                        className="buttonTabs1 cursor-pointer"
                      >
                        Verify Transfer
                      </div>
                    </td>
                  ) : (
                    <td>
                      {invoice?.paymentStatus === "Paid" ? (
                        <div
                          className="btn-ext1"
                          style={{ backgroundColor: "rgb(18, 191, 15)" }}
                        >
                          {invoice?.paymentStatus}
                        </div>
                      ) : invoice?.paymentStatus === "Unpaid" ? (
                        <div
                          className="btn-ext1"
                          style={{
                            backgroundColor: "rgb(233, 236, 239)",
                            color: "rgb(51, 51, 51)",
                          }}
                        >
                          {invoice?.paymentStatus}
                        </div>
                      ) : (
                        <div
                          className="btn-extPartial"
                          style={{ backgroundColor: "#9B9B9B" }}
                        >
                          {invoice?.paymentStatus}
                        </div>
                      )}
                    </td>
                  )}
                  <td className="text-right">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-icon-only text-light"
                        href="#pablo"
                        role="button"
                        size="sm"
                        color=""
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={() => {
                            // setOpenModel(true);
                            // setJobDeletion(user._id);
                            this.handleOpenModelPayments();
                          }}
                          //   onClick={() => {

                          //     api.deleteJob(user._id);
                          //     allJobs();
                          // }}
                        >
                          Payments
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>

                  {/* <td>{invoice?.paymentType}</td> */}

                  {/* <td className="text-right">
              {!this.state.expanded ? (
                <button
                className="btn-toggle"
                onClick={() => this.toggleExpander()}
                >
                <i className="fas fa-chevron-down"></i>
                </button>
              ) : (
                <button
                  className="btn-toggle"
                  onClick={() => this.toggleExpander()}
                  >
                  {" "}
                  <i className="fas fa-chevron-up"></i>
                  </button>
                  )}
                </td> */}
                </>

                {
                  <Modal
                    className="modal-dialog-centered BillingInvoiceModalPayment"
                    toggle={(e) => {
                      this.handleOpenModelPayments();
                    }}
                    isOpen={this.state.openModelPayments}
                  >
                    <div className="BillingInvoiceModalBoxPayment">
                      <div className="InvoiceClassesModalBoxPayment">
                        <div className="InvoiceModalBox">
                          <div className="ModalInvoiceText">
                            <span style={{ fontWeight: 600 }}>
                              {bill?.job[0]?.courseName}
                            </span>
                            <span style={{ fontWeight: 600 }}> - </span>
                            <span style={{ fontWeight: 600 }}>
                              {invoice?.month}
                            </span>
                            {/* <span style={{ fontWeight: 700 }}>#{invoice?.invoice.substring(15)}</span> */}
                          </div>
                          <div>
                            <img
                              onClick={(e) => {
                                this.handleOpenModelPayments();
                              }}
                              alt="..."
                              src={
                                require("../../../assets/img/icons/common/GroupCross.png")
                                  .default
                              }
                            />
                          </div>
                        </div>
                        <div className="ModalClassesBox">
                          <div className="ModalClassesBoxPayment">
                            <div
                              className="ModalClassesBoxText45"
                              style={{ width: "14vw" }}
                            >
                              Total Bill Amount
                            </div>
                            <div className="ModalClassesBoxTextBlue">
                              {/* {invoice?.month} */}${invoice?.amount}
                            </div>
                          </div>
                          <div className="ModalClassesBoxPayment">
                            <div
                              className="ModalClassesBoxText45"
                              style={{ width: "14vw" }}
                            >
                              Paid Amount
                            </div>
                            <div className="ModalClassesBoxTextBlue">
                              {/* {invoice?.noOfClasses} Classes */}$
                              {invoice?.amount - invoice?.remainingAmount}
                            </div>
                          </div>
                          <div className="ModalClassesBoxPayment">
                            <div
                              className="ModalClassesBoxText45"
                              style={{ width: "14vw" }}
                            >
                              Pending Amount
                            </div>
                            <div className="ModalClassesBoxTextBlue">
                              {/* {bill.totalClassDuration} Hours */}$
                              {invoice?.remainingAmount}
                            </div>
                          </div>
                          {/* <div className="ModalClassesBoxPayment">
                            <div className="ModalClassesBoxText45">
                              Rate Per Hour
                            </div>
                            <div className="ModalClassesBoxTextBlue">
                              $ {bill?.job[0]?.priceperHour}
                            </div>

                          </div> */}
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "0.365vw",
                          height: "33.26.5vw",
                          overflowY: "scroll",
                          marginTop: "41px",
                        }}
                        className="BillingInvoiceModalBoxTable PrimaryScrollQualificationPayment"
                      >
                        {/* style={{width:"100%", height:"766px"}} */}
                        <Table
                          className="align-items-center table-flush PrimaryScrollQualificationPayment"
                          style={{ overflowY: "scroll" }}
                          responsive
                        >
                          <thead
                            className="thead-light sticky-top"
                            // style={{ top: "-3px" }}
                            style={{
                              position: "absolute",
                              width: "33.8020833vw",
                              zIndex: "1000",
                              top: "10vw",
                            }}
                          >
                            <tr>
                              <th
                                scope="col"
                                style={{
                                  paddingLeft: "1.563vw",
                                  textAlign: "start",
                                  fontSize: "0.625vw",
                                  fontWeight: 700,
                                  width: "11.2673611vw",
                                }}
                              >
                                Date
                              </th>
                              <th
                                scope="col"
                                style={{
                                  paddingRight: "1.563vw",
                                  textAlign: "start",
                                  fontSize: "0.625vw",
                                  fontWeight: 700,
                                  width: "11.2673611vw",
                                }}
                              >
                                Amount
                              </th>
                              <th
                                scope="col"
                                style={{
                                  paddingRight: "1.563vw",
                                  textAlign: "start",
                                  fontSize: "0.625vw",
                                  fontWeight: 700,
                                  width: "11.2673611vw",
                                }}
                              >
                                Payment Method
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {invoice?.payments.map((payment, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    fontSize: "0.729vw",
                                    width: "11.267361vw",
                                    height: "auto",
                                    fontWeight: 600,
                                    color: "#9A9A9A",
                                    paddingLeft: "1.563vw",
                                    textAlign: "start",
                                  }}
                                  scope="row"
                                >
                                  {payment.date.substring(15)}
                                </td>
                                <td
                                  style={{
                                    fontSize: "0.729vw",
                                    width: "11.267361vw",
                                    height: "auto",
                                    fontWeight: 600,
                                    color: "#9A9A9A",
                                    paddingLeft: "1.563vw",
                                    textAlign: "start",
                                  }}
                                  scope="row"
                                >
                                  {payment.amount}
                                </td>
                                <td
                                  style={{
                                    fontSize: "0.729vw",
                                    width: "11.267361vw",
                                    height: "auto",
                                    fontWeight: 600,
                                    color: "#9A9A9A",
                                    paddingLeft: "1.563vw",
                                    textAlign: "start",
                                  }}
                                  scope="row"
                                >
                                  {payment.paymentMethod}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Modal>
                }

                <Modal
                  className="modal-dialog-centered BillingInvoiceModal"
                  toggle={(e) => {
                    this.handleOpenModel();
                  }}
                  isOpen={this.state.openModel}
                >
                  <div className="BillingInvoiceModalBox">
                    <div className="InvoiceClassesModalBox">
                      <div className="InvoiceModalBox">
                        <div className="ModalInvoiceText">
                          <span style={{ fontWeight: 700 }}>
                            #{invoice?.invoice.substring(15)}
                          </span>
                        </div>
                        <div>
                          <img
                            className="cursor-pointer"
                            onClick={(e) => {
                              this.handleOpenModel();
                            }}
                            alt="..."
                            src={
                              require("../../../assets/img/icons/common/GroupCross.png")
                                .default
                            }
                          />
                        </div>
                      </div>
                      <div className="ModalClassesBox">
                        <div className="ModalClassesBox1">
                          <div className="ModalClassesBoxText45">Month</div>
                          <div className="ModalClassesBoxTextBlue">
                            {invoice?.month}
                          </div>
                        </div>
                        <div className="ModalClassesBox1">
                          <div className="ModalClassesBoxText45">
                            Total Classes
                          </div>
                          <div className="ModalClassesBoxTextBlue">
                            {invoice?.noOfClasses} Classes
                          </div>
                        </div>
                        <div className="ModalClassesBox1">
                          <div className="ModalClassesBoxText45">
                            Classes Duration
                          </div>
                          <div className="ModalClassesBoxTextBlue">
                            {bill.totalClassDuration} Hours
                          </div>
                        </div>
                        <div className="ModalClassesBox1">
                          <div className="ModalClassesBoxText45">
                            Rate Per Hour
                          </div>
                          <div className="ModalClassesBoxTextBlue">
                            $ {bill?.job[0]?.priceperHour}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "0.365vw",
                        height: "33.26.5vw",
                        overflowY: "scroll",
                        marginTop: "41px",
                      }}
                      className="BillingInvoiceModalBoxTable PrimaryScrollQualification12121"
                    >
                      {/* style={{width:"100%", height:"766px"}} */}
                      <Table
                        className="align-items-center table-flush PrimaryScrollQualification12121"
                        style={{ overflowY: "scroll" }}
                        responsive
                      >
                        <thead
                          className="thead-light sticky-top"
                          // style={{ top: "-3px" }}
                          style={{
                            position: "absolute",
                            width: "20.65vw",
                            zIndex: "1000",
                            top: "11.75vw",
                          }}
                        >
                          <tr>
                            <th
                              scope="col"
                              style={{
                                paddingLeft: "1.563vw",
                                textAlign: "start",
                                fontSize: "0.625vw",
                                fontWeight: 700,
                                width: "10.325vw",
                              }}
                            >
                              Classes Date
                            </th>
                            <th
                              scope="col"
                              style={{
                                paddingRight: "1.563vw",
                                textAlign: "end",
                                fontSize: "0.625vw",
                                fontWeight: 700,
                                width: "10.325vw",
                              }}
                            >
                              Charge
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoice?.totalClasses.map((clas) => (
                            <tr>
                              <td
                                style={{
                                  fontSize: "0.729vw",
                                  fontWeight: 600,
                                  color: "#9A9A9A",
                                  paddingLeft: "1.563vw",
                                  textAlign: "start",
                                }}
                                scope="row"
                              >
                                {clas.date}
                              </td>
                              <td
                                style={{
                                  fontSize: "0.729vw",
                                  fontWeight: 700,
                                  color: "#9A9A9A",
                                  paddingRight: "1.563vw",
                                  textAlign: "end",
                                }}
                                scope="row"
                              >
                                $
                                {bill?.job[0]?.priceperHour *
                                  bill.totalClassDuration}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Modal>
              </tr>
            )
          // : null
        )}

        <VerifyTransferModal
          bill={bill}
          handleLoader={(isloading) => this.setState({ isloading })}
          handleMessageField={(message) => this.setState({ message })}
          setState={this.setState}
          message={this.state.message}
          isOpen={this.state.openModel2}
          isloading={this.state.isloading}
          handleModal={this.handleOpenModel2}
          invoice={this.state.selectedInvoice}
          editBankBilling={this.editBankBilling}
          handlePayedOnDate={this.handlePayedOnDate}
          bankBillingRequest={this.state.bankBillingRequest}
          editBankBillingRejected={this.editBankBillingRejected}
        />
      </>,
    ];
  }
}

const Tables = () => {
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(
    moment(new Date()).format("MMMM, YYYY")
  );

  const [billing, setBilling] = useState([]);
  const [firstBilling, setFirstBilling] = useState([]);
  const [showAnotherButton, setShowAnotherButton] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [value, setValue] = useState(new Date());
  const [searchText2, setSearchText2] = useState("");
  const [fromFunction, setFromFunction] = useState(false);
  const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false);
  const [dataForPdf, setDataForPdf] = useState(null);

  const handleTabClick = (tabValue) => {
    setTab(tabValue);
    // getBilling(tabValue, selectedMonth1);
    setShowAnotherButton(false); // Close the additional button when clicking a different tab
  };
  const handlePendingButtonClick = () => {
    setTab(2);
    // getBilling(2, selectedMonth1);
    setShowAnotherButton(true);
  };

  const handleBulkChargeAction = () => {
    // Handle click logic for the additional button here
    setLoading(true);

    const data = {
      month: selectedMonth,
    };

    api
      .bulkCharge(data)
      .then((res) => {
        toast.success("Action Performed Successfully.");
        setLoading(false);

        setTimeout(() => {
          getBilling();
        }, 500);
      })
      .catch((error) => {
        setLoading(false);

        console.log({ error });
      });
  };
  const [months, setMonths] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);

  const [selectedMonth1, setSelectedMonth1] = useState(0);
  const [stats, setStats] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Tuition Stats",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        maxBarThickness: 10,
      },
    ],
  });
  const size = useWindowSize();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthSelected1 = monthNames[selectedMonth1];
  let totalIncome = 0;
  let receivedAmount = 0;
  let receiveableAmount = 0;
  let tutorSalaries = 0;

  function incrementMonth1() {
    const newMonth = selectedMonth1 + 1;
    if (newMonth <= 11) {
      // setSelectedMonth(newMonth);
      setSelectedMonth1(newMonth);
      getBilling(tab, newMonth);
    }
  }
  function decrementMonth1() {
    const oldMonth = selectedMonth1 - 1;
    if (oldMonth >= 0) {
      // setSelectedMonth(oldMonth);
      setSelectedMonth1(oldMonth);
      getBilling(tab, oldMonth);
    }
  }
  function handleRendering(render) {
    if (render == true) {
      setFromFunction(!fromFunction);
    }
  }
  async function getBilling(tab, startDate) {
    setLoading(true);
    try {
      const billing = await api.getBilling();
      setBilling(billing.data);
      setLoading(false);
    } catch (e) {
      console.log(e.response);
      setLoading(false);
    }
  }

  function getMonth() {
    try {
      const val = value.getMonth();
      setValue(val);
    } catch (e) {
      e.response;
    }
  }

  const prepareDataForPdf = () => {
    const formattedData = {};

    billing?.result?.forEach((invoicesList) => {
      if (invoicesList.job[0].jobStatus !== 1 && !isEmpty(invoicesList.tutor)) {
        const tutorName = invoicesList?.tutor[0]?.name || "N/A";

        // Check if the tutor name is not already in the formattedData
        if (!formattedData[tutorName]) {
          formattedData[tutorName] = {
            bankInfo: !isEmpty(invoicesList?.tutorBank)
              ? `${invoicesList?.tutorBank[0]?.bankName}, ${invoicesList?.tutorBank[0]?.accountNumber}`
              : "N/A",
            tutionClasses: [],
            sumOfAllTutionClassesSalary: "$0",
          };
        }

        invoicesList?.invoices
          .filter((invoice) => invoice.month === selectedMonth)
          .forEach((invoice) => {
            const tuitionName = invoicesList.job[0]?.courseName || "N/A";
            const invoiceNumber = `#${invoice.invoice} (${invoice.type})`;
            const tuteeName = `${invoicesList?.tutee[0]?.name || "N/A"} (${
              invoicesList?.tutee[0]?.email || ""
            })`;
            const totalBill = `$${invoice.amount || 0}`;
            const tutorSalary = `$${invoice.salary || 0}`;

            const tutionClassData = {
              tutionName: tuitionName,
              invoiceNumber: invoiceNumber,
              tuteeName: tuteeName,
              totalBill: totalBill,
              tutorSalary: tutorSalary,
            };

            // Add the tutionClassData to the tutor's tutionClasses
            formattedData[tutorName].tutionClasses.push(tutionClassData);

            // Update the sum of all tutor salaries
            formattedData[tutorName].sumOfAllTutionClassesSalary = `$${
              parseFloat(
                formattedData[tutorName].sumOfAllTutionClassesSalary.slice(1)
              ) + parseFloat(tutorSalary.slice(1))
            }`;
          });
      }
    });
    setDataForPdf(formattedData);
  };

  const handleDownloadPdf = () => {
    let pdf;
    let downloadedPdfName = `Tutors Invoice ${selectedMonth}.pdf`;
    const input = document.getElementById("divToPrint");

    html2canvas(input, {
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("divToPrint").style.display = "block";
        clonedDoc.getElementById("divToPrint").style.width = "1056";
        clonedDoc.getElementById("divToPrint").style.height = "1536";
      },
    }).then((canvas) => {
      if (canvas.width > canvas.height) {
        pdf = new jsPDF("l", "mm", [canvas.width + 20, canvas.height + 40]);
      } else {
        pdf = new jsPDF("p", "mm", [canvas.height + 20, canvas.width + 40]);
      }

      const imgData = canvas.toDataURL("PNG", 1.0);
      pdf.addImage(imgData, 10, 10, canvas.width, canvas.height);
      setTimeout(() => {
        pdf.save(downloadedPdfName);
      }, 1000);
    });
  };

  const handleTutorsPdfModal = () => {
    !pdfModalIsOpen && prepareDataForPdf();

    setTimeout(() => {
      setPdfModalIsOpen(!pdfModalIsOpen);
    }, 400);
  };

  useEffect(async () => {
    const today = new Date();
    handleRendering(false);
    setSelectedMonth1(today.getMonth());
    getMonth();
    getBilling(0, selectedMonth1);
  }, [fromFunction, firstBilling]);

  return (
    <>
      <Header />
      <hr className="header-hr" />
      <Container
        className={window.innerWidth <= 1366 ? "mt--8" : "mt--9"}
        fluid
      >
        <Row>
          <div className="col">
            <Card className="shadow">
              <div className="billingTopRow">
                <div className="card-data">
                  <h3
                    className="mb-0"
                    style={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    Billing for
                  </h3>
                  <div className="tutor-salries-data">
                    <Input
                      placeholder="Select Month"
                      type="month"
                      defaultValue={new Date().toISOString().substr(0, 7)}
                      onChange={(e) => {
                        setSelectedMonth(
                          moment(e.target.value).format("MMMM, YYYY")
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="billingSearchUserBlock">
                  {showAnotherButton && (
                    <>
                      {/* <CSVLink
                        className="export-csv-btn"
                        data={prepareDataForCsv()}
                      >
                        Export
                      </CSVLink> */}
                      <Button
                        className="export-csv-btn"
                        onClick={handleTutorsPdfModal}
                      >
                        Export Tutor Salaries
                      </Button>

                      <Button
                        className="btn_after_button"
                        onClick={!loading && handleBulkChargeAction}
                      >
                        Charge All Bills
                        {loading && (
                          <Spinner
                            style={{
                              width: 16,
                              height: 16,
                              marginLeft: "5px",
                            }}
                          />
                        )}
                      </Button>
                    </>
                  )}
                </div>
              </div>

              <div className="px-2 billingTabsRow">
                <TabsUnstyled defaultValue={0} className="unstyledTab">
                  <Tabs
                    tabsList={[
                      "All",
                      "Paid Bills",
                      "Pending Bills",
                      "Bank Transfer Requests",
                    ]}
                    handleTab={(tabId) =>
                      tabId === 2
                        ? handlePendingButtonClick(tabId)
                        : handleTabClick(tabId)
                    }
                  />
                </TabsUnstyled>

                <div
                  style={{ minWidth: "310px" }}
                  className={size.width > 840 ? "jobPosting-search" : "main2"}
                >
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <SearchIcon />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="search"
                      type="search"
                      label="Search"
                      style={{
                        backgroundColor: "white",
                        color: "Black",
                        height: "47px",
                        marginRight: "12px",
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Search by Tutee, Tutor or Course"
                    ></Input>
                  </InputGroup>
                </div>
              </div>
              {tab === 0 && (
                <BillingTabTable
                  key={tab}
                  isLoading={loading}
                  billing={billing}
                  searchText={searchText}
                  getBilling={getBilling}
                  // paymentStatusList={["unpaid"]}
                  selectedMonth={selectedMonth}
                  handleRendering={handleRendering}
                  selectedMonth1={selectedMonth1}
                  paymentStatusList={[
                    "paid",
                    "unpaid",
                    "review",
                    "partial paid",
                  ]}
                />
              )}
              {tab === 1 && (
                <BillingTabTable
                  key={tab}
                  billing={billing}
                  isLoading={loading}
                  searchText={searchText}
                  getBilling={getBilling}
                  paymentStatusList={["paid"]}
                  selectedMonth={selectedMonth}
                  handleRendering={handleRendering}
                  selectedMonth1={selectedMonth1}
                />
              )}
              {tab === 2 && (
                <BillingTabTable
                  key={tab}
                  isLoading={loading}
                  billing={billing}
                  searchText={searchText}
                  getBilling={getBilling}
                  paymentStatusList={["unpaid"]}
                  selectedMonth={selectedMonth}
                  handleRendering={handleRendering}
                  selectedMonth1={selectedMonth1}
                />
              )}
              {tab === 3 && (
                <BillingTabTable
                  key={tab}
                  isLoading={loading}
                  billing={billing}
                  searchText={searchText}
                  getBilling={getBilling}
                  paymentStatusList={["review"]}
                  selectedMonth={selectedMonth}
                  handleRendering={handleRendering}
                  selectedMonth1={selectedMonth1}
                />
              )}

              {billing?.result
                ?.filter((bill) => {
                  if (searchText === "") {
                    return bill;
                  } else if (
                    bill.tutee[0].name
                      .toLocaleLowerCase()
                      .includes(searchText.toLocaleLowerCase())
                  ) {
                    return bill;
                  } else if (
                    bill.tutee[0].memberId
                      .toLocaleLowerCase()
                      .includes(searchText.toLocaleLowerCase())
                  ) {
                    return bill;
                  } else if (
                    bill.job[0]?.course[0]?.courseName
                      ?.toLocaleLowerCase()
                      .includes(searchText.toLocaleLowerCase())
                  ) {
                    return bill;
                  } else if (
                    bill.job[0]?.courseLevel
                      ?.toLocaleLowerCase()
                      .includes(searchText.toLocaleLowerCase())
                  ) {
                    return bill;
                  } else if (
                    bill?.tutor[0]?.name
                      .toLocaleLowerCase()
                      .includes(searchText.toLocaleLowerCase())
                  ) {
                    return bill;
                  } else if (
                    bill?.tutor[0]?.memberId
                      .toLocaleLowerCase()
                      .includes(searchText.toLocaleLowerCase())
                  ) {
                    return bill;
                  } else if (
                    bill.invoices[0].month
                      .toLocaleLowerCase()
                      .includes(searchText.toLocaleLowerCase())
                  ) {
                    return bill;
                  }
                })
                .map((results) => {
                  // for(let i=0; i<)
                  results.job[0]?.jobStatus !== 1 &&
                    results?.invoices.map((invoice) => {
                      let currentTab =
                        tab === 1
                          ? ["Paid"]
                          : tab === 2
                          ? ["Unpaid"]
                          : tab === 3
                          ? ["review"]
                          : ["Paid", "Unpaid"];
                      selectedMonth === invoice.month &&
                      currentTab.includes(invoice.paymentStatus)
                        ? ((receivedAmount =
                            receivedAmount +
                            invoice.amount +
                            invoice.remainingAmount * -1),
                          (receiveableAmount =
                            receiveableAmount + invoice.remainingAmount),
                          (totalIncome = totalIncome + invoice.amount),
                          (tutorSalaries = tutorSalaries + invoice?.salary))
                        : null;
                    });
                })}
              <CardFooter style={{ backgroundColor: "#F6F9FC" }}>
                <Container>
                  <Row style={{ marginLeft: "25%" }}>
                    <Col>
                      Total Income:
                      <span style={{ color: "#454545", fontWeight: "bold" }}>
                        {" "}
                        ${totalIncome}
                      </span>
                    </Col>
                    <Col>
                      Received:
                      <span style={{ color: "#12BF0F", fontWeight: "bold" }}>
                        &nbsp;${receivedAmount}
                      </span>
                    </Col>
                    <Col>
                      Receivable:
                      <span style={{ color: "#454545", fontWeight: "bold" }}>
                        &nbsp;${receiveableAmount}
                      </span>
                    </Col>
                    <Col>
                      Tutor Salaries:
                      <span style={{ color: "#454545", fontWeight: "bold" }}>
                        &nbsp;${tutorSalaries}
                      </span>
                    </Col>
                  </Row>
                </Container>
              </CardFooter>
            </Card>
          </div>
        </Row>

        {/* <TutorTable /> */}
      </Container>

      <ViewTutorsBillingModal
        invoiceMonth={selectedMonth}
        dataForPdf={dataForPdf}
        isOpen={pdfModalIsOpen}
        handleModal={handleTutorsPdfModal}
        handleDownloadPdf={handleDownloadPdf}
      />
    </>
  );
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default Tables;

const BillingTabTable = ({
  isLoading,
  billing,
  searchText,
  getBilling,
  selectedMonth,
  selectedMonth1,
  handleRendering,
  paymentStatusList = [],
}) => {
  return (
    <div style={{ height: "58vh", overflowY: "scroll" }}>
      <Table className="align-items-center table-flush table-Pad" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col" style={{ fontSize: "0.625vw", fontWeight: 600 }}>
              INVOICE
            </th>
            <th scope="col" style={{ fontSize: "0.625vw", fontWeight: 600 }}>
              TUTEE
            </th>
            <th scope="col" style={{ fontSize: "0.625vw", fontWeight: 600 }}>
              COURSE
            </th>

            <th scope="col" style={{ fontSize: "0.625vw", fontWeight: 600 }}>
              TUTOR
            </th>
            <th style={{ fontSize: "0.625vw", fontWeight: 600 }} scope="col">
              TUTOR SALARY
            </th>

            <th scope="col" style={{ fontSize: "0.625vw", fontWeight: 600 }}>
              TOTAL BILL
            </th>
            <th style={{ fontSize: "0.625vw", fontWeight: 600 }} scope="col">
              PAID
            </th>
            <th style={{ fontSize: "0.625vw", fontWeight: 600 }} scope="col">
              PENDING
            </th>

            <th
              style={{
                fontSize: "0.625vw",
                fontWeight: 600,
                backgroundColor: "##E1F0FF",
              }}
              scope="col"
            >
              INVOICE STATUS
            </th>

            <th scope="col"></th>
          </tr>
        </thead>
        {/* {billing?.result ? ( */}
        <tbody>
          {isLoading ? (
            <tr
              style={{
                width: "100%",
                height: "100%",
                marginLeft: "10rem",
              }}
            >
              <td colSpan={9}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ReactLoading
                    type={"spin"}
                    color={"#ff6700"}
                    height={30}
                    width={30}
                  />
                </div>
              </td>
            </tr>
          ) : (
            billing?.result
              ?.filter((bill) => {
                const invoiceIds = bill.invoices?.map((item) =>
                  item.invoice.substring(15).toLowerCase()
                );
                if (searchText === "") {
                  return bill;
                } else if (
                  bill.tutee[0].name
                    .toLocaleLowerCase()
                    .includes(searchText.toLocaleLowerCase())
                ) {
                  return bill;
                } else if (
                  bill.tutee[0].memberId
                    .toLocaleLowerCase()
                    .includes(searchText.toLocaleLowerCase())
                ) {
                  return bill;
                } else if (
                  bill.job[0]?.course[0]?.courseName
                    ?.toLocaleLowerCase()
                    .includes(searchText.toLocaleLowerCase())
                ) {
                  return bill;
                } else if (
                  bill.job[0]?.courseLevel
                    ?.toLocaleLowerCase()
                    .includes(searchText.toLocaleLowerCase())
                ) {
                  return bill;
                } else if (
                  bill?.tutor[0]?.name
                    .toLocaleLowerCase()
                    .includes(searchText.toLocaleLowerCase())
                ) {
                  return bill;
                } else if (
                  bill?.tutor[0]?.memberId
                    .toLocaleLowerCase()
                    .includes(searchText.toLocaleLowerCase())
                ) {
                  return bill;
                } else if (
                  invoiceIds.find((invoice) =>
                    invoice.includes(searchText.toLocaleLowerCase())
                  )
                ) {
                  return bill;
                }
              })
              .map((bill, index) => (
                <TableRow
                  bill={bill}
                  key={index}
                  index={index + 1}
                  invoices={bill?.invoices}
                  paymentStatus={paymentStatusList}
                  selectedMonth={selectedMonth}
                  selectedMonth1={selectedMonth1}
                  getBilling={getBilling}
                  fromFunction={() => {
                    handleRendering(true);
                  }}
                />
              ))
          )}
        </tbody>
      </Table>
    </div>
  );
};

// export default BillingTabTable;
